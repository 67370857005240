import React from "react";
import { graphql } from 'gatsby'
import { Container } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";
import SEO from "../components/seo"
import ListingContent from "../components/ListingContent/ListingContent";
const NewsLandingModule = loadable(() => import("../components/NewsLanding/NewsLanding"));
const ElfSight = loadable(() => import("../components/ElfSight/ElfSight"));

const NewsLanding = ({ data }, props) => {
  const PageData = data?.strapiPage
  const allNews = data?.allStrapiBlog.edges
  const elfData = data?.site.siteMetadata?.elfSight
  return (
    <Layout popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top"></div>

      {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
        return (
          <>
            {module.strapi_component === "page-modules.global-module" && module.select_module === "blog" &&
              <div className="inner-wrapper">
                <Container>
                  <NewsLandingModule title={PageData.title} data={allNews} />
                </Container>
              </div>
            }
            {module.strapi_component === "page-modules.plain-content" && <ListingContent {...module} />}
            {module.strapi_component === "page-modules.global-module" && module.select_module === "social_wall" && elfData.review &&
              <ElfSight code={elfData.social_wall} social_wall className="social-wall" />
            }
          </>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
  )
}

export default NewsLanding



export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
        }
    }
    allStrapiBlog(filter: {publish: {eq: true}}, sort: {fields: date, order: DESC}) {
        edges {
          node {
            date(formatString: "DD MMM, yyyy")
            title
            slug
            strapi_id
            tile_image {
              alternativeText
              url
            }
            category {
                strapi_json_value
            }
            imagetransforms {
              tile_image_Transforms
            }
          }
        }
    }
    site {
      siteMetadata {
          elfSight {
            review
            social_wall
          }
      }
    }
  }
`