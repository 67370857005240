import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './assets/styles/_index.scss'
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const ListingContent = (props) => {
    return (
        <>
            {(props.title && props.content) &&
                <div className='listing-content-wrapper'>
                    <Container>
                        <Row>
                            <Col xl={8}>
                                <h1 className='inner-intro-heading'>{props.title}</h1>
                                <ContentModule Content={props.content?.data?.content} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </>
    )
}

export default ListingContent